import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FaBook, FaCheck,FaClock } from "react-icons/fa";
import Swal from "sweetalert2";
import { db } from "../firebase/firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";

// Imposta l'elemento radice per il modal
Modal.setAppElement("#root");

const SchedaModal = ({ isOpen, onRequestClose, schedeAllenamento, userId }) => {
  const [selectedExercises, setSelectedExercises] = useState([]);
  const [noteModalIsOpen, setNoteModalIsOpen] = useState(false);
  const [selectedNote, setSelectedNote] = useState("");

  // Funzione per caricare lo stato degli esercizi da Firestore
  const fetchExerciseStates = async () => {
    if (userId) {
      try {
        const docRef = doc(db, "userExerciseStates", userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setSelectedExercises(docSnap.data().exercises || []);
        } else {
          setSelectedExercises([]); // Se il documento non esiste, resetta lo stato
        }
      } catch (error) {
        console.error("Errore nel recuperare lo stato degli esercizi:", error);
      }
    }
  };

  // Funzione per salvare lo stato degli esercizi in Firestore
  const saveExerciseStates = async (exercises) => {
    if (userId) {
      try {
        const docRef = doc(db, "userExerciseStates", userId);
        await setDoc(docRef, { exercises });
      } catch (error) {
        console.error("Errore nel salvare lo stato degli esercizi:", error);
      }
    }
  };

  // Carica lo stato degli esercizi da Firestore ogni volta che il modal viene aperto
  useEffect(() => {
    if (isOpen) {
      fetchExerciseStates();
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    // Pulizia: rimuovi la classe quando il componente viene smontato
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isOpen, userId]);

  // Funzione per gestire la selezione degli esercizi
  const toggleExerciseSelection = (exerciseKey) => {
    const updatedExercises = selectedExercises.includes(exerciseKey)
      ? selectedExercises.filter((key) => key !== exerciseKey)
      : [...selectedExercises, exerciseKey];

    setSelectedExercises(updatedExercises);
    saveExerciseStates(updatedExercises);
  };

  const resetExercises = async () => {
    setSelectedExercises([]);
    await saveExerciseStates([]); // Resetta lo stato su Firestore
  };

  const handleReset = () => {
    Swal.fire({
      title: "Sei sicuro di togliere la spunta agli esercizi già completati?",
      text: "Le modifiche non salvate andranno perse!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sì, grazie!",
      cancelButtonText: "Annulla",
    }).then((result) => {
      if (result.isConfirmed) {
        resetExercises();
      }
    });
  };

  const openNoteModal = (note) => {
    setSelectedNote(note);
    setNoteModalIsOpen(true);
  };

  const closeNoteModal = () => {
    setNoteModalIsOpen(false);
    setSelectedNote("");
  };

  // Funzione per rendere i dettagli della scheda
  const renderDetails = (data) => {
    return Object.keys(data).map((key) => {
      if (key === "exercises") {
        let exercises;
        try {
          exercises =
            typeof data[key] === "string" ? JSON.parse(data[key]) : data[key];
        } catch (error) {
          console.error("Errore nel parsing di exercises:", error);
          exercises = {};
        }

        return (
          <div key={key}>
            <h4 className="flex items-center text-xl font-semibold">
              <p className="mr-2">Ecco gli esercizi assegnati</p>
            </h4>
            <label className="labelTitle">
              Clicca su un esercizio per marcarlo come completato!
            </label>
            {Object.keys(exercises).map((day) => (
              <div key={day} className="mt-2 mb-4 p-2 border rounded-lg">
                <h5 className="font-semibold text-blue-600" style={{ fontSize: 22 }}>
                  Giorno {day}
                </h5>
                <ul className="pl-2">
                  {Array.isArray(exercises[day]) ? (
                    exercises[day].map((exercise, index) => {
                      const exerciseKey = `${day}-${index}`;
                      const isSelected =
                        selectedExercises.includes(exerciseKey);

                      return (
                        <li
                          key={index}
                          className={`mb-2 cursor-pointer flex justify-between items-center transition-transform duration-500 ${
                            isSelected ? "scale-up" : ""
                          }`}
                        >
                          <div className="flex items-center">
                            <div className="mr-2">
                              {isSelected ? (
                                <FaCheck
                                  className="text-green-500"
                                  size={18}
                                />
                              ) : (
                                <FaClock className="text-amber-400" size={18} />
                              )}
                            </div>
                            <span
                              style={{ fontSize: 18 }}
                              onClick={() =>
                                toggleExerciseSelection(exerciseKey)
                              }
                              className={`${
                                isSelected
                                  ? "text-green-600 "
                                  : "text-black"
                              }`}
                            >
                              <strong>{exercise.name}</strong>{" "}
                              {exercise.duration
                                ? `${exercise.duration} minuti`
                                : `${exercise.series} x ${exercise.reps}`}
                            </span>
                          </div>
                          <div>
                            <button
                              onClick={() => openNoteModal(exercise.note)}
                              className="ml-2"
                            >
                              <FaBook className="text-blue-500" size={22} />
                            </button>
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    <li className="mb-2">Dati degli esercizi non disponibili</li>
                  )}
                </ul>
              </div>
            ))}
          </div>
        );
      } else if (key !== "id") {
        return (
          <p key={key} className="text-lg">
            <span className="font-bold">{key}:</span>{" "}
            {typeof data[key] === "object"
              ? JSON.stringify(data[key], null, 2)
              : data[key]}
          </p>
        );
      }
      return null;
    });
  };

  // Filtra le schede per l'ID dell'utente
  const schedePerUtente = schedeAllenamento.filter(
    (scheda) => scheda.id === userId
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50 overflow-auto"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
       <div className="bg-white rounded-lg shadow-lg max-w-full sm:max-w-md lg:max-w-3xl xl:max-w-4xl w-full p-6 overflow-auto max-h-[90vh]">
          <div>
          <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold mb-4 flex items-center justify-center">Scheda Allenamento</h2>
          </div>
          {schedePerUtente && schedePerUtente.length > 0 ? (
            <div>
              {schedePerUtente.map((scheda) => (
                <div
                  key={scheda.id}
                  className="mb-4 p-4 border border-gray-200 rounded-lg shadow-md"
                >
                  <h4 className="text-xl font-semibold mb-2">{scheda.id.toUpperCase()}</h4>
                  <div className="space-y-2 container-details" style={{ overflowY: 'auto' }}>
                    {renderDetails(scheda)}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>Nessuna scheda disponibile per l'utente {userId}</p>
          )}
          <div className="modalEndButtons mt-4">
            <button
              onClick={onRequestClose}
              className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
            >
              Chiudi
            </button>
            <button
              className="ml-2 bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600"
              onClick={handleReset}
            >
              Reset
            </button>
          </div>
        </div>
      </Modal>

      {/* Modal per le note */}
      <Modal
        isOpen={noteModalIsOpen}
        onRequestClose={closeNoteModal}
        className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white rounded-lg shadow-lg max-w-3xl w-full p-6 overflow-auto max-h-[90vh]">
          <h2 className="text-2xl font-bold mb-4">Note dell'Esercizio</h2>
          <p className="text-lg">
            {selectedNote || "Nessuna nota disponibile per questo esercizio."}
          </p>
          <button
            onClick={closeNoteModal}
            className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
          >
            Chiudi
          </button>
        </div>
      </Modal>
    </>
  );
};

export default SchedaModal;
