import {React} from 'react';
import PropTypes from 'prop-types';

const Box =  ({ title, backgroundColor, buttonText, onButtonClick, description, icon: Icon }) => (
  <div className="box" style={{ backgroundColor, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: '20px' }}>
    {Icon && <div style={{backgroundColor:'#FFFFFF3B',  
    borderRadius: '50%', 
    width:'40px', 
    height:'40px',  
    display: 'flex', 
    justifyContent:'center', 
    alignItems:'center'}}>
      <Icon className="icon" 
    style={{ fontSize: '24px', color: 'white' , 
     }} /></div>}

    <h2 style={{ color: 'white', fontWeight: '700', fontSize: '24px' }}>{title}</h2>
    <p>{description}</p>
    <button
      className='bottoneApri'
      style={{ backgroundColor: '#fff' , color:'black'}} // Utilizzo di darken per scurire il colore
      onClick={onButtonClick}
    >
      {buttonText}
    </button>
  </div>
);

Box.propTypes = {
  title: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
};

export default Box;
