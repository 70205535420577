import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaBook, FaPills, FaPlus, FaTrash } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCookieBite,
  faDrumstickBite,
  faWheatAwn,
} from "@fortawesome/free-solid-svg-icons";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import Swal from "sweetalert2";
import Header from "../header";

const EditNutrition = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const [nutritionData, setNutritionData] = useState({
    giorniOn: { carbo: "", proteine: "", grassi: "" },
    giorniOff: { carbo: "", proteine: "", grassi: "" },
    giorniOnAlimentazione: [
      { pasto: "", alimenti: [{ nome: "", grammi: "" }] },
    ],
    giorniOffAlimentazione: [
      { pasto: "", alimenti: [{ nome: "", grammi: "" }] },
    ],
  });

  const [loading, setLoading] = useState(true);
  const [giorniOnAlimentazione, setGiorniOnAlimentazione] = useState({});
  const [giorniOffAlimentazione, setGiorniOffAlimentazione] = useState({});

  const [integrationData, setIntegrationData] = useState({
    mattina: { grammi: "", tipo: "" },
    intraWorkout: { grammi: "", tipo: "" },
    postAllenamento: { grammi: "", tipo: "" },
    preNanna: { grammi: "", tipo: "" },
  });
  const [additionalNotes, setAdditionalNotes] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDataAndNutrition = async () => {
      const userDocRef = doc(db, "users", id);
      const dietaDocRef = doc(db, "dieta", id);
  
      try {
        // Recupera i dati dell'utente
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setUserData(userDocSnap.data());
        } else {
          await Swal.fire({
            title: "Errore",
            text: "L'utente non esiste. Contatta l'assistenza.",
            icon: "error",
            confirmButtonText: "OK",
          });
          navigate("/home");
          setLoading(false);
          return;
        }
  
        // Recupera i dati della dieta
        const dietaDocSnap = await getDoc(dietaDocRef);
        if (dietaDocSnap.exists()) {
          const data = dietaDocSnap.data();
          // Imposta i dati della dieta
          setNutritionData({
            giorniOn: data.giorniOn || { carbo: "", proteine: "", grassi: "" },
            giorniOff: data.giorniOff || { carbo: "", proteine: "", grassi: "" },
            giorniOnAlimentazione: Array.isArray(data.giorniOnAlimentazione)
              ? data.giorniOnAlimentazione
              : [],
            giorniOffAlimentazione: Array.isArray(data.giorniOffAlimentazione)
              ? data.giorniOffAlimentazione
              : [],
          });
          setIntegrationData(data.integrazione || {
            mattina: { grammi: "", tipo: "" },
            intraWorkout: { grammi: "", tipo: "" },
            postAllenamento: { grammi: "", tipo: "" },
            preNanna: { grammi: "", tipo: "" },
          });
          setAdditionalNotes(data.noteAggiuntive || "");
  
          // Imposta i dati per i giorni On e Off
          setGiorniOnAlimentazione(
            Array.isArray(data.giorniOnAlimentazione)
              ? data.giorniOnAlimentazione
              : []
          );
          setGiorniOffAlimentazione(
            Array.isArray(data.giorniOffAlimentazione)
              ? data.giorniOffAlimentazione
              : []
          );
        } else {
          // Inizializza un nuovo documento se non esiste
          await setDoc(dietaDocRef, {
            giorniOn: { carbo: "", proteine: "", grassi: "" },
            giorniOff: { carbo: "", proteine: "", grassi: "" },
            integrazione: {
              mattina: { grammi: "", tipo: "" },
              intraWorkout: { grammi: "", tipo: "" },
              postAllenamento: { grammi: "", tipo: "" },
              preNanna: { grammi: "", tipo: "" },
            },
            noteAggiuntive: "",
            giorniOnAlimentazione: [], // Cambiato a array vuoto
            giorniOffAlimentazione: [], // Cambiato a array vuoto
          });
          setNutritionData({
            giorniOn: { carbo: "", proteine: "", grassi: "" },
            giorniOff: { carbo: "", proteine: "", grassi: "" },
            giorniOnAlimentazione: [], // Cambiato a array vuoto
            giorniOffAlimentazione: [], // Cambiato a array vuoto
          });
          setIntegrationData({
            mattina: { grammi: "", tipo: "" },
            intraWorkout: { grammi: "", tipo: "" },
            postAllenamento: { grammi: "", tipo: "" },
            preNanna: { grammi: "", tipo: "" },
          });
          setAdditionalNotes("");
        }
  
        setLoading(false);
      } catch (error) {
        console.error("Error fetching documents: ", error);
        setLoading(false);
      }
    };
  
    fetchUserDataAndNutrition();
  }, [id, navigate]);
  
  const handleGoHome = () => {
    navigate("/home"); // Naviga alla home page
  };

  const handleNutritionChange = (e, dayType, nutrient) => {
    const { value } = e.target;
    setNutritionData((prevData) => ({
      ...prevData,
      [dayType]: {
        ...prevData[dayType],
        [nutrient]: value,
      },
    }));
  };

  const handleIntegrationChange = (e, timeOfDay, type) => {
    const { value } = e.target;
    setIntegrationData((prevData) => ({
      ...prevData,
      [timeOfDay]: {
        ...prevData[timeOfDay],
        [type]: value,
      },
    }));
  };

  const handleAdditionalNotesChange = (e) => {
    setAdditionalNotes(e.target.value);
  };

  const handleAddAlimento = (pastoType, pastoIndex) => {
    const newAlimento = { nome: "", grammi: "" };
  
    setNutritionData((prevData) => {
      const updatedAlimenti = [
        ...prevData[pastoType][pastoIndex].alimenti,
        newAlimento,
      ];
      const updatedGiorniAlimentazione = prevData[pastoType].map(
        (pasto, index) =>
          index === pastoIndex ? { ...pasto, alimenti: updatedAlimenti } : pasto
      );
      return { ...prevData, [pastoType]: updatedGiorniAlimentazione };
    });
  };
  

  const handleRemoveLastAlimento = (pastoIndex) => {
    setNutritionData((prevData) => {
      const updatedAlimenti = prevData.giorniOnAlimentazione[pastoIndex].alimenti.length > 0
        ? prevData.giorniOnAlimentazione[pastoIndex].alimenti.slice(0, -1) // Rimuove l'ultimo alimento
        : []; // Se l'array è vuoto, ritorna un array vuoto
  
      // Aggiorna il pasto con l'array di alimenti modificato
      const updatedGiorniOnAlimentazione = prevData.giorniOnAlimentazione.map((pasto, index) =>
        index === pastoIndex ? { ...pasto, alimenti: updatedAlimenti } : pasto
      );
  
      // Ritorna il nuovo stato aggiornato
      return { ...prevData, giorniOnAlimentazione: updatedGiorniOnAlimentazione };
    });
  };
  
  

 

  const handleAlimentoChange = (
    pastoType,
    pastoIndex,
    alimentoIndex,
    field,
    value
  ) => {
    setNutritionData((prevData) => {
      const updatedAlimenti = prevData[pastoType][pastoIndex].alimenti.map(
        (alimento, index) =>
          index === alimentoIndex ? { ...alimento, [field]: value } : alimento
      );
      const updatedGiorniAlimentazione = prevData[pastoType].map(
        (pasto, index) =>
          index === pastoIndex ? { ...pasto, alimenti: updatedAlimenti } : pasto
      );
      return { ...prevData, [pastoType]: updatedGiorniAlimentazione };
    });
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dietaDocRef = doc(db, "dieta", id);
  
    try {
      await updateDoc(dietaDocRef, {
        ...nutritionData,
        integrazione: integrationData,
        noteAggiuntive: additionalNotes,
        giorniOnAlimentazione: nutritionData.giorniOnAlimentazione,
        giorniOffAlimentazione: nutritionData.giorniOffAlimentazione,
      });
      Swal.fire("Dati aggiornati con successo!", "", "success");
      setTimeout(() => navigate("/home"), 1500);
    } catch (error) {
      Swal.fire("Errore durante l'aggiornamento dei dati", "", "error");
      console.error("Error updating document: ", error);
    }
  };
  

  if (loading)
    return (
      <p className="flex items-center justify-center h-full">Caricamento...</p>
    );

  return (
    <div className="container mx-auto px-4 py-16 mt-4">
      <Header isVisible={true} /> {/* Header sempre visibile */}
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-4">
        Assegna una dieta
      </h1>
      <form
        onSubmit={handleSubmit}
        className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-md"
      >
        <div className="mb-4">
          <span className="text-gray-700 text-xl mb-2">
            Stai assegnando a:{" "}
          </span>
          <span className="font-bold text-xl">
            {userData.nome.toUpperCase()} {userData.cognome.toUpperCase()}
          </span>
          <div className="text-xl">
            Peso: <span className="text-xl font-bold">{userData.peso} kg</span>
          </div>
          <div className="text-xl">
            Altezza:{" "}
            <span className="text-xl font-bold">{userData.altezza} cm</span>
          </div>
        </div>

        {/*INSERISCI QUI LE NUOVE INFO*/}
        <div>
          <div className="inline-flex items-center justify-center"> <h3 className="text-xl font-semibold mb-2">Giorni On </h3>   <button
            type="button"
            onClick={() =>
              setNutritionData((prevData) => ({
                ...prevData,
                giorniOnAlimentazione: [
                  ...prevData.giorniOnAlimentazione,
                  {
                    pasto: `Pasto ${prevData.giorniOnAlimentazione.length + 1}`,
                    alimenti: [{ nome: "", grammi: "" }],
                  },
                ],
              }))
            }
            className="flex items-center justify-center  ml-4 mb-2 bg-green-500 text-white p-1 rounded"
          >
            <FaPlus /><span className=" p-1">Aggiungi pasto</span>
            
          </button></div>
         
          {nutritionData.giorniOnAlimentazione.map((pasto, pastoIndex) => (
            <div key={pastoIndex} className="mb-4">
              <h4 className="font-semibold">{pasto.pasto}</h4>
              {pasto.alimenti.map((alimento, alimentoIndex) => (
                <div key={alimentoIndex} className="flex space-x-2 mb-2">
                  <input
                    type="text"
                    value={alimento.nome}
                    onChange={(e) =>
                      handleAlimentoChange(
                        "giorniOnAlimentazione",
                        pastoIndex,
                        alimentoIndex,
                        "nome",
                        e.target.value
                      )
                    }
                    placeholder="Nome Alimento"
                    className="border px-2 rounded"
                  />
                  <input
                    type="number"
                    value={alimento.grammi}
                    onChange={(e) =>
                      handleAlimentoChange(
                        "giorniOnAlimentazione",
                        pastoIndex,
                        alimentoIndex,
                        "grammi",
                        e.target.value
                      )
                    }
                    placeholder="Grammi"
                    className="border p-2 w-40 rounded"
                  />
                </div>
              ))}
              <div className="inline-flex items-center justify-center">
              <button
                type="button"
                onClick={() =>
                  handleAddAlimento("giorniOnAlimentazione", pastoIndex)
                }
                className="flex items-center space-x-1 bg-green-500 text-white p-2 rounded"
              >
                <FaPlus />
                <span>Aggiungi Alimento</span>
              </button>
              <button
                    type="button"
                    className="bg-red-500 ml-2 text-white p-3 rounded"
                    onClick={() => handleRemoveLastAlimento(pastoIndex)}
                  >
                    <FaTrash />
                  </button>
                  </div>
            </div>
          ))}
        </div>
        <div>
  <div className="inline-flex items-center justify-center">
    <h3 className="text-xl font-semibold mb-2">Giorni Off</h3>
    <button
      type="button"
      onClick={() =>
        setNutritionData((prevData) => ({
          ...prevData,
          giorniOffAlimentazione: [
            ...prevData.giorniOffAlimentazione,
            {
              pasto: `Pasto ${prevData.giorniOffAlimentazione.length + 1}`,
              alimenti: [{ nome: "", grammi: "" }],
            },
          ],
        }))
      }
      className="flex items-center justify-center ml-4 mb-2 bg-green-500 text-white p-1 rounded"
    >
      <FaPlus /><span className="p-1">Aggiungi pasto</span>
     
    </button>
  </div>

  {nutritionData.giorniOffAlimentazione.map((pasto, pastoIndex) => (
    <div key={pastoIndex} className="mb-4">
      <h4 className="font-semibold">{pasto.pasto}</h4>
      {pasto.alimenti.map((alimento, alimentoIndex) => (
        <div key={alimentoIndex} className="flex space-x-2 mb-2">
          <input
            type="text"
            value={alimento.nome}
            onChange={(e) =>
              handleAlimentoChange(
                "giorniOffAlimentazione",
                pastoIndex,
                alimentoIndex,
                "nome",
                e.target.value
              )
            }
            placeholder="Nome Alimento"
            className="border px-2 rounded"
          />
          <input
            type="number"
            value={alimento.grammi}
            onChange={(e) =>
              handleAlimentoChange(
                "giorniOffAlimentazione",
                pastoIndex,
                alimentoIndex,
                "grammi",
                e.target.value
              )
            }
            placeholder="Grammi"
            className="border p-2 w-20 sm:w-32 md:w-40 lg:w-48 xl:w-60 rounded"
          />
        </div>
      ))}
      <div className="inline-flex items-center justify-center">
        <button
          type="button"
          onClick={() =>
            handleAddAlimento("giorniOffAlimentazione", pastoIndex)
          }
          className="flex items-center space-x-1 bg-green-500 text-white p-2 rounded"
        >
          <FaPlus />
          <span>Aggiungi Alimento</span>
        </button>
        <button
          type="button"
          className="bg-red-500 ml-2 text-white p-3 rounded"
          onClick={() => handleRemoveLastAlimento("giorniOffAlimentazione", pastoIndex)}
        >
          <FaTrash />
        </button>
      </div>
    </div>
  ))}
</div>


        {/*Integrazione */}
        <div className="overflow-x-auto">
          <div className="font-bold mb-2 text-xl">
            Inserisci i valori nutrizionali
          </div>
          <table className="min-w-full border-collapse border border-gray-400 text-m">
            <thead>
              <tr>
                <th className="border text-xl border-gray-400 px-2 py-1">
                  Giorni
                </th>
                <th
                  className="border text-lg border-gray-400 text-amber-500 py-1"
                  style={{ width: "90px" }}
                >
                  Carbo{" "}
                  <FontAwesomeIcon
                    icon={faWheatAwn}
                    size="xs"
                    className="ml-1"
                  />
                </th>
                <th
                  className="border text-lg border-gray-400 text-lime-500 py-1"
                  style={{ width: "110px" }}
                >
                  Proteine{" "}
                  <FontAwesomeIcon
                    icon={faDrumstickBite}
                    size="xs"
                    className="ml-1"
                  />
                </th>
                <th
                  className="border text-lg border-gray-400 text-rose-500 py-1"
                  style={{ width: "100px" }}
                >
                  Grassi{" "}
                  <FontAwesomeIcon
                    icon={faCookieBite}
                    size="xs"
                    className="ml-1"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border text-xl border-gray-400 px-2 py-1">
                  Giorni ON
                </td>
                <td className="border border-gray-400 px-2 py-1">
                  <input
                    type="text"
                    value={nutritionData.giorniOn.carbo}
                    onChange={(e) =>
                      handleNutritionChange(e, "giorniOn", "carbo")
                    }
                    className="w-full border rounded py-1 px-1 text-xl"
                  />
                </td>
                <td className="border border-gray-400 px-2 py-1">
                  <input
                    type="text"
                    value={nutritionData.giorniOn.proteine}
                    onChange={(e) =>
                      handleNutritionChange(e, "giorniOn", "proteine")
                    }
                    className="w-full border rounded py-1 px-1 text-xl"
                  />
                </td>
                <td className="border border-gray-400 px-2 py-1">
                  <input
                    type="text"
                    value={nutritionData.giorniOn.grassi}
                    onChange={(e) =>
                      handleNutritionChange(e, "giorniOn", "grassi")
                    }
                    className="w-full border rounded py-1 px-1 text-xl"
                  />
                </td>
              </tr>
              <tr>
                <td className="border text-xl border-gray-400 px-2 py-1">
                  Giorni OFF
                </td>
                <td className="border border-gray-400 px-2 py-1">
                  <input
                    type="text"
                    value={nutritionData.giorniOff.carbo}
                    onChange={(e) =>
                      handleNutritionChange(e, "giorniOff", "carbo")
                    }
                    className="w-full border rounded py-1 px-1 text-xl"
                  />
                </td>
                <td className="border border-gray-400 px-2 py-1">
                  <input
                    type="text"
                    value={nutritionData.giorniOff.proteine}
                    onChange={(e) =>
                      handleNutritionChange(e, "giorniOff", "proteine")
                    }
                    className="w-full border rounded py-1 px-1 text-xl"
                  />
                </td>
                <td className="border border-gray-400 px-2 py-1">
                  <input
                    type="text"
                    value={nutritionData.giorniOff.grassi}
                    onChange={(e) =>
                      handleNutritionChange(e, "giorniOff", "grassi")
                    }
                    className="w-full border rounded py-1 px-1 text-xl"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mt-8 text-xl">
          <div className="font-bold text-2xl mb-6 flex items-center justify-center">
            <span>Integrazione</span>
            <FaPills size={22} className="ml-2 text-indigo-500" />
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full border-collapse border border-gray-400">
              <thead>
                <tr>
                  <th className="border text-xl border-gray-400 px-2 py-1">
                    Momento
                  </th>
                  <th className="border text-lg border-gray-400 py-1">
                    Quantità in grammi
                  </th>
                  <th className="border text-lg border-gray-400 py-1">
                    Tipo di integrazione
                  </th>
                </tr>
              </thead>
              <tbody>
                {["mattina", "intraWorkout", "postAllenamento", "preNanna"].map(
                  (timeOfDay) => (
                    <tr key={timeOfDay}>
                      <td className="border text-xl border-gray-400 px-2 py-1 capitalize">
                        {timeOfDay.replace(/([A-Z])/g, " $1").trim()}
                      </td>
                      <td className="border border-gray-400 px-2 py-1">
                        <input
                          type="text"
                          name="grammi"
                          placeholder="N/A"
                          value={integrationData[timeOfDay].grammi}
                          onChange={(e) =>
                            handleIntegrationChange(e, timeOfDay, "grammi")
                          }
                          className="w-full px-2 py-1 border rounded-md"
                        />
                      </td>
                      <td className="border border-gray-400 px-2 py-1">
                        <input
                          type="text"
                          name="tipo"
                          placeholder="N/A"
                          value={integrationData[timeOfDay].tipo}
                          onChange={(e) =>
                            handleIntegrationChange(e, timeOfDay, "tipo")
                          }
                          className="w-full px-2 py-1 border rounded-md"
                        />
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="mt-8">
          <div className="font-bold text-xl mb-2 flex items-center justify-center">
            Note Aggiuntive <FaBook size={22} className="ml-2 text-blue-500" />
          </div>
          <textarea
            value={additionalNotes}
            onChange={handleAdditionalNotesChange}
            placeholder="Aggiungi eventuali note aggiuntive qui..."
            className="w-full h-32 border border-gray-400 rounded p-2 text-lg"
          />
        </div>
        <div className="flex justify-between items-center">
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white  py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4"
        >
          Salva Modifiche
        </button>
        <button
            onClick={handleGoHome}
            className=" px-4 py-2 text-white bg-blue-500 hover:bg-blue-600 rounded focus:outline-none mt-3.5"
          >
            Torna alla Home
          </button>
          </div>
      </form>
    </div>
  );
};

export default EditNutrition;
