import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../../contexts/authContext";
import { db, auth } from "../../firebase/firebase";
import { getDocs, collection, query, where } from "firebase/firestore";
import { isEmpty } from "lodash"; // Libreria lodash per lavorare con oggetti
import UserTable from "../Card"; // Assicurati di avere il percorso corretto
import Box from "../BoxContainer";
import UserIconUomo from "../../assets/icons8-avatar-uomo-96.png"; // Importa il file PNG
import UserIconDonna from "../../assets/icons8-avatar-donna-96.png"; // Importa il file PNG
import Header from "../header/index"; // Assicurati che il percorso sia corretto
import { useNavigate } from "react-router-dom"; // Importa useNavigate
import Swal from 'sweetalert2';

const fetchUserFromFirestore = async (email) => {
  const q = query(collection(db, "users"), where("email", "==", email));
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))[0]; // Assumiamo che l'email sia unica e quindi prendiamo solo il primo risultato
  }
  return null;
};

const fetchDataFromFirestore = async () => {
  const querySnapshot = await getDocs(collection(db, "users"));
  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

export default function Home() {
  const { currentUser } = useAuth();
  const specialEmail = "test@test.it";
  const [userData, setUserData] = useState([]);
  const [currentUserData, setCurrentUserData] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null); // Stato per l'utente selezionato
  const [loading, setLoading] = useState(false); // Stato di caricamento

  const userId = auth.currentUser?.uid; // Ottieni l'ID dell'utente autenticato
  const navigate = useNavigate(); 

  const fetchCurrentUserData = useCallback(async () => {
    setLoading(true); // Inizia il caricamento
    const data = await fetchUserFromFirestore(currentUser.email);
    setCurrentUserData(data);
    setLoading(false); // Fine caricamento
  }, [currentUser.email]);

  const fetchUserData = useCallback(async () => {
    setLoading(true); // Inizia il caricamento
    const data = await fetchDataFromFirestore();
    setUserData(data);
    setLoading(false); // Fine caricamento
  }, []);

  useEffect(() => {
    fetchCurrentUserData();
    fetchUserData();
  }, [fetchCurrentUserData, fetchUserData]);

  const handleViewProgressClick = () => {
    navigate(`/progress-data/${userId}`); // Naviga verso la pagina della progressione con l'ID utente
  };
  //alert per le modifiche
  const handleModificheAlert = () => {
    Swal.fire({
      title: 'MODIFICHE IN CORSO',
      text: 'L\'app è in continuo aggiornamento, potresti notare dei cambiamenti nel corso dell\'utilizzo',
      icon: 'info',
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'my-confirm-button'
      }
    });
  };
  const isCoach = currentUser.email === specialEmail;
  const userName = currentUserData
    ? `${currentUserData.nome} ${currentUserData.cognome}`
    : currentUser.email;
  const userSex = currentUserData?.sesso;
  const userIcon = userSex === "Maschile" ? UserIconUomo : UserIconDonna;

  // Funzione per gestire il clic su un utente nella tabella
  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  return (
    <div className="container mx-auto px-4 py-10">
      <Header isVisible={true} /> {/* Header sempre visibile */}
      {/* Spinner di caricamento */}
      {loading && (
        <div className="flex justify-center items-center h-screen">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
        </div>
      )}
      {!loading && // Contenuto mostrato solo se non è in corso il caricamento
        (isCoach ? (
          <div className="bg-gray-100 mt-4 p-6 rounded-lg shadow-md">
            <div className="avatar-container mb-4">
              <img
                src={UserIconUomo}
                alt="User Avatar"
                className="avatar-icon"
              />
            </div>
            <h1 className="text-3xl font-bold text-center text-gray-800 mb-4">
              Benvenuto Coach
            </h1>
            <p className="text-lg text-center text-gray-600 mb-8">
              Ecco la tua lista di utenti:
            </p>
            {/* Mostra il totale dei clienti */}
            <p className="text-center text-lg font-bold text-gray-700 mb-4">
              Totale utenti: {userData.length}
            </p>
            <div className="flex flex-col space-y-4">
              {isEmpty(userData) ? (
                <p className="text-center text-gray-500">
                  Nessun utente trovato.
                </p>
              ) : (
                <UserTable users={userData} onUserClick={handleUserClick} />
              )}
            </div>
          </div>
        ) : (
          <div className="bg-white mt-4 p-6 rounded-lg shadow-md text-center">
            <div className="avatar-container mb-4">
              <img src={userIcon} alt="User Avatar" className="avatar-icon" />
            </div>
            <h1 className="text-2xl font-bold text-gray-800 mb-2">
              Ciao {userName.toUpperCase()}
              <br />
              pronto ad allenarti?
            </h1>
            <div className="flex items-center">  
            <button onClick={handleViewProgressClick}>
              <div className="custom-border">Progressione passata</div>
          </button>
          <button onClick={handleModificheAlert}>
          <div className="custom-border1 ml-4">Modifiche in corso...</div>
          </button>
          </div>
            
            <Box />
          </div>
        ))}
    </div>
  );
}
