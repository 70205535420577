import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Swal from "sweetalert2";
import WeightSelector from "../components/WeightSelector";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { getAuth } from "firebase/auth";

// Imposta l'elemento radice per il modal
Modal.setAppElement("#root");

const AnamnesiModal = ({
  isOpen,
  onRequestClose,
  userId,
  onUpdateAnamnesi,
}) => {
  const [peso, setWeight] = useState(70);
  const [giorni, setGiorni] = useState(1);
  const [anamnesiData, setAnamnesiData] = useState({});

  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          const userDocRef = doc(db, "users", currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const data = userDocSnap.data();
            setAnamnesiData(data);
            setWeight(data.peso || 70);
            setGiorni(data.giorni || 1);
          } else {
            console.log("Nessun documento trovato per l'utente");
          }
        } catch (error) {
          console.error("Errore nel recupero dei dati utente:", error);
        }
      }
    };

    fetchUserData();
  }, [currentUser]);

  useEffect(() => {
    if (onUpdateAnamnesi) {
      onUpdateAnamnesi({ ...anamnesiData, peso, giorni });
    }
  }, [peso, giorni, anamnesiData, onUpdateAnamnesi]);

  const handleSave = async () => {
    try {
      const result = await Swal.fire({
        title: "Confermi di voler salvare?",
        text: "Questa azione salverà i tuoi dati!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sì, salva!",
        cancelButtonText: "Annulla",
      });

      if (result.isConfirmed && peso != null) {
        const userDocRef = doc(db, "users", currentUser.uid);
        await setDoc(userDocRef, { peso, giorni }, { merge: true });

        Swal.fire(
          "Salvato!",
          "I tuoi dati sono stati salvati con successo.",
          "success"
        );

        onRequestClose();
      }
    } catch (error) {
      Swal.fire(
        "Errore",
        "C'è stato un errore nel salvare i tuoi dati.",
        "error"
      );
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    // Pulizia: rimuovi la classe quando il componente viene smontato
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50 overflow-auto"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-6 overflow-auto max-h-[90vh]">
        <h2 className="text-2xl font-bold mb-4">Informazioni Anamnesi</h2>

        {anamnesiData ? (
          <div>
            <h3 className="text-xl font-semibold mb-2">
              {anamnesiData.nome} {anamnesiData.cognome}
            </h3>
            <div className="text-lg ">
              <p><span className="font-bold">Peso:</span> {peso} kg</p>
              <p><span className="font-bold">Altezza:</span> {anamnesiData.altezza} cm</p>
              <p><span className="font-bold">Sesso:</span> {anamnesiData.sesso}</p>
              <p><span className="font-bold">Giorni di Allenamento:</span> {giorni}</p>
            </div>
            {anamnesiData.risposte && typeof anamnesiData.risposte === "object" ? (
              <div className=" text-lg mb-4">
             
                <p><span className="font-bold">Dieta:</span> {anamnesiData.risposte.dieta}</p>
                <p><span className="font-bold">Infortunio:</span> {anamnesiData.risposte.infortunio}</p>
                <p><span className="font-bold">Problematiche Alimentari:</span> {anamnesiData.risposte.problematicheAlimentari}</p>
              </div>
            ) : null}
            <div className="mt-4">
              <h4 className="text-lg font-bold">Modifica il tuo peso</h4>
              <WeightSelector value={peso} onChange={setWeight} />
            </div>
          </div>
        ) : (
          <p>Nessuna informazione di anamnesi disponibile per l'utente.</p>
        )}
        <div className="flex justify-between mt-4">
          <button
            onClick={onRequestClose}
            className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
          >
            Chiudi
          </button>
          <button
            onClick={handleSave}
            className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600"
          >
            Salva
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AnamnesiModal;
