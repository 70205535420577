import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";

const ViewInformations = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const closeUserInfo = () => {
    navigate("/home"); // Puoi modificare la rotta secondo le tue necessità
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userRef = doc(db, "users", id);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();
          setSelectedUser(userData);
        } else {
          setError("Nessun utente trovato");
        }
      } catch (error) {
        setError("Errore nel recupero dei dati");
        console.error("Errore nel recupero dei dati:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (error)
    return (
      <div className="flex flex-col items-center justify-center min-h-screen text-center text-red-500">
        <p>{error}</p>
      </div>
    );

  if (loading)
    return (
      <div className="flex flex-col items-center justify-center min-h-screen text-center">
        <p>Caricamento...</p>
      </div>
    );

  // Helper per generare la lista dei campi
  const renderField = (label, value) => (
    value ? (
      <li>
        <strong>{label}:</strong> {value}
      </li>
    ) : null
  );

  return (
    <div className="container mx-auto px-4 py-10">
      {selectedUser && (
        <div className="bg-white p-4 mt-6 rounded-lg shadow-md relative">
          <h2 className="text-xl font-bold">Informazioni Utente</h2>
          
          {selectedUser.nome && (
            <p>
              <strong>Nome:</strong> {selectedUser.nome}
            </p>
          )}
          {selectedUser.cognome && (
            <p>
              <strong>Cognome:</strong> {selectedUser.cognome}
            </p>
          )}
          {selectedUser.email && (
            <p>
              <strong>Email:</strong> {selectedUser.email}
            </p>
          )}
          {selectedUser.peso && (
            <p>
              <strong>Peso:</strong> {selectedUser.peso} kg
            </p>
          )}
          {selectedUser.altezza && (
            <p>
              <strong>Altezza:</strong> {selectedUser.altezza} cm
            </p>
          )}

          <h2 className="text-xl font-bold mt-6">Risposte Aggiuntive</h2>
          <ul className="list-disc pl-5 mt-4">
            {renderField("Attività", selectedUser.risposte?.attivita)}
            {renderField("Obiettivo Breve Termine", selectedUser.risposte?.obiettivoBreveTermine)}
            {renderField("Obiettivo Lungo Termine", selectedUser.risposte?.obiettivoLungoTermine)}
            {renderField("Orario Allenamento", selectedUser.risposte?.orarioAllenamento)}
            {renderField("Carattere", selectedUser.risposte?.carattere)}
            {renderField("Distretti Muscolari", selectedUser.risposte?.distrettiMuscolari)}
            {renderField("Sport Passati", selectedUser.risposte?.sportPassati)}
            {renderField("Integratori", selectedUser.risposte?.integratori)}
            {renderField("Alimenti Fastidiosi", selectedUser.risposte?.alimentiFastidiosi)}
            {renderField("Alimenti Indispensabili", selectedUser.risposte?.alimentiIndispensabili)}
            {renderField("Accumulo Grasso", selectedUser.risposte?.accumuloGrasso)}
            {renderField("Dimagrimento Zone", selectedUser.risposte?.dimagrimentoZone)}
            {renderField("Acqua", selectedUser.risposte?.acqua)}
            {renderField("Weekend Cene", selectedUser.risposte?.weekendCene)}
          </ul>
        </div>
      )}
      <div className="pt-6 pb-4">
        <button
          onClick={closeUserInfo}
          className="w-full px-4 py-2 text-white bg-blue-500 hover:bg-blue-600 rounded focus:outline-none"
        >
          Torna alla Home
        </button>
      </div>
    </div>
  );
};

export default ViewInformations;
