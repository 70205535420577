import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FaChart, FaPills } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCookieBite,
  faDrumstickBite,
  faWheatAwn,
  faChartColumn
} from "@fortawesome/free-solid-svg-icons";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";

// Imposta l'elemento radice per il modal
Modal.setAppElement("#root");

const colorMap = {
  mattina: "text-blue-500",
  intraWorkout: "text-blue-500",
  postAllenamento: "text-blue-500",
  preNanna: "text-blue-500",
};

const NutritionModal = ({ isOpen, onRequestClose, userId, onUpdateNutrition }) => {
  const [nutritionData, setNutritionData] = useState(null);
  const [integrationData, setIntegrationData] = useState({
    mattina: { grammi: "", tipo: "" },
    intraWorkout: { grammi: "", tipo: "" },
    postAllenamento: { grammi: "", tipo: "" },
    preNanna: { grammi: "", tipo: "" },
  });
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [giorniOnAlimentazione, setGiorniOnAlimentazione] = useState([]);
  const [giorniOffAlimentazione, setGiorniOffAlimentazione] = useState([]);

  useEffect(() => {
    // Gestione del body overflow per lo scroll
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    
    const fetchNutritionData = async () => {
      if (userId) {
        const dietaDocRef = doc(db, "dieta", userId);
        try {
          const dietaDocSnap = await getDoc(dietaDocRef);
          if (dietaDocSnap.exists()) {
            const data = dietaDocSnap.data();

            setNutritionData(data);
            setIntegrationData(data.integrazione || integrationData);
            setAdditionalNotes(data.noteAggiuntive || "");

            // Imposta i dati per i giorni On e Off
            setGiorniOnAlimentazione(data.giorniOnAlimentazione || []);
            setGiorniOffAlimentazione(data.giorniOffAlimentazione || []);
          }
        } catch (error) {
          console.error("Errore nel recuperare i dati nutrizionali:", error);
        }
      }
    };

    fetchNutritionData();
  }, [userId, isOpen]);


  const handleSave = async () => {
    try {
      if (onUpdateNutrition) {
        onUpdateNutrition({
          ...nutritionData,
          integrazione: integrationData,
          noteAggiuntive: additionalNotes,
        });
      }

      onRequestClose();
    } catch (error) {
      console.error("Errore nel gestire la chiusura del modal:", error);
    }
  };

  const renderAlimentazione = (alimentazione) => {
    return alimentazione.map((pasto, index) => (
      <div key={index} className="mt-4 p-4 border border-gray-400 rounded-lg shadow-md">
        <h5 className="text-xl font-semibold text-blue-600" style={{ fontSize: 22 }}>
          {`Pasto ${index + 1}`}
        </h5>
        <ul className="pl-2">
          {pasto.alimenti && pasto.alimenti.length > 0 ? (
            pasto.alimenti.map((alimento, idx) => (
              <li key={idx} className="mb-2 flex justify-between items-center">
                <div>
                  <p className="font-semibold">{alimento.nome}</p>
                  <p className="italic">{alimento.grammi} grammi</p>
                </div>
              </li>
            ))
          ) : (
            <li className="mb-2 text-center text-gray-500">Nessun dato disponibile per questo pasto</li>
          )}
        </ul>
      </div>
    ));
  };

  
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-6 max-h-[70vh] flex flex-col mt-16">
        <h2 className="text-2xl font-bold mb-4 flex items-center justify-center sticky top-0 bg-white z-10">
          La tua alimentazione
        </h2>
        <div>
        </div>
        <div className="flex-1 overflow-auto">
          <div className="mt-2 text-xl">
            {nutritionData ?  
          <p className="text-s text-center mb-4 ">Visualizza tutti i dati realtivi alla tua alimentazione e/o integrazione in base al pacchetto scelto.</p>
          : <p></p>}
            {/* Verifica se ci sono dati nutrizionali */}
            {nutritionData ? (
              <>
                <div className=" mb-4 flex items-center justify-center">
                  <span className="font-bold text-2xl ">Alimentazione Giorni ON</span>
                </div>
                {renderAlimentazione(giorniOnAlimentazione)}

                <div className="font-bold text-2xl mt-8 mb-4 flex items-center justify-center">
                  <span>Alimentazione Giorni OFF</span>
                </div>
                {renderAlimentazione(giorniOffAlimentazione)}
              
                <div>
                  <div className="overflow-x-auto">
                    <div className="font-bold text-2xl text-center mt-8"> 
                      <FontAwesomeIcon
                        icon={faChartColumn}
                        size='m'
                        className="mr-2 text-blue-500"
                      />
                      Valori nutrizionali
                    </div>
                    <div className="text-gray-700 mb-2">
                      <label className="labelTitle ">
                        I valori riportati sono indicati in grammi (g)
                      </label>
                    </div>
                    <table className="min-w-full border-collapse border border-gray-400 text-m">
                      <thead>
                        <tr>
                          <th className="border text-xl border-gray-400 px-2 py-1 text-center">
                            Giorni
                          </th>
                          <th
                            className="border text-lg border-gray-400 text-amber-500 py-1 text-center"
                            style={{ width: "90px" }}
                          >
                            Carbo{" "}
                            <FontAwesomeIcon
                              icon={faWheatAwn}
                              size="xs"
                              className="ml-1"
                            />
                          </th>
                          <th
                            className="border text-lg border-gray-400 text-lime-500 py-1 text-center"
                            style={{ width: "110px" }}
                          >
                            Proteine{" "}
                            <FontAwesomeIcon
                              icon={faDrumstickBite}
                              size="xs"
                              className="ml-1"
                            />
                          </th>
                          <th
                            className="border text-lg border-gray-400 text-rose-500 py-1 text-center"
                            style={{ width: "100px" }}
                          >
                            Grassi{" "}
                            <FontAwesomeIcon
                              icon={faCookieBite}
                              size="xs"
                              className="ml-1"
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="border text-xl border-gray-400 px-2 py-1 text-center">
                            Giorni ON
                          </td>
                          <td className="border border-gray-400 px-2 py-1 text-center">
                            <input
                              type="text"
                              value={nutritionData.giorniOn.carbo}
                              readOnly
                              className="w-full border rounded py-1 px-1 text-xl text-center"
                            />
                          </td>
                          <td className="border border-gray-400 px-2 py-1 text-center">
                            <input
                              type="text"
                              value={nutritionData.giorniOn.proteine}
                              readOnly
                              className="w-full border rounded py-1 px-1 text-xl text-center"
                            />
                          </td>
                          <td className="border border-gray-400 px-2 py-1 text-center">
                            <input
                              type="text"
                              value={nutritionData.giorniOn.grassi}
                              readOnly
                              className="w-full border rounded py-1 px-1 text-xl text-center"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="border text-xl border-gray-400 px-2 py-1 text-center">
                            Giorni OFF
                          </td>
                          <td className="border border-gray-400 px-2 py-1 text-center">
                            <input
                              type="text"
                              value={nutritionData.giorniOff.carbo}
                              readOnly
                              className="w-full border rounded py-1 px-1 text-xl text-center"
                            />
                          </td>
                          <td className="border border-gray-400 px-2 py-1 text-center">
                            <input
                              type="text"
                              value={nutritionData.giorniOff.proteine}
                              readOnly
                              className="w-full border rounded py-1 px-1 text-xl text-center"
                            />
                          </td>
                          <td className="border border-gray-400 px-2 py-1 text-center">
                            <input
                              type="text"
                              value={nutritionData.giorniOff.grassi}
                              readOnly
                              className="w-full border rounded py-1 px-1 text-xl text-center"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-8 text-xl">
                    <div className="font-bold text-2xl mb-6 flex items-center justify-center">
                      <FaPills size={30} className="text-blue-500 mr-2 mb-1" />
                      Integrazione
                    </div>
                    <div className="flex flex-wrap gap-4">
                      {["mattina", "intraWorkout", "postAllenamento", "preNanna"].map(
                        (timeOfDay) => {
                          const data = integrationData[timeOfDay];
                          if (!data || !data.grammi || !data.tipo) return null;

                          const textColor = colorMap[timeOfDay] || "text-gray-800";

                          return (
                            <div
                              key={timeOfDay}
                              className="bg-gray-100 p-4 border border-gray-400 rounded-lg shadow-md w-full sm:w-1/2 lg:w-1/4"
                            >
                              <div
                                className={`font-semibold text-lg capitalize mb-2 ${textColor}`}
                              >
                                {timeOfDay.replace(/([A-Z])/g, " $1").trim()}
                              </div>
                              <div className="mb-2">
                                <span className="font-medium italic">
                                  Quantità:
                                </span>{" "}
                                <span className="font-bold">{data.grammi} g</span>
                              </div>
                              <div>
                                <span className="font-medium italic">Tipo:</span>{" "}
                                <span className="font-bold">{data.tipo}</span>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                  <div className="mt-8">
                    <div className="font-bold text-xl mb-2">Note Aggiuntive</div>
                    <textarea
                      value={additionalNotes}
                      readOnly
                      className="w-full h-32 border border-gray-400 rounded p-2 text-lg bg-gray-100"
                    />
                  </div>
                </div>
              </>
            ) : (
              <p className="text-center text-lg text-gray-500">
                Dieta non disponibile per questo utente.
              </p>
            )}
          </div>
        </div>

        <div className="modalEndButtons mt-4">
          <button
            onClick={handleSave}
            className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
          >
            Chiudi
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default NutritionModal;
